import React from "react"
import { graphql } from "gatsby"
import { Typography } from "@material-ui/core"

import { PostsQuery } from "../../types/graphql-types"

import Layout from "../components/Layout"
import Posts from "../components/Posts"
import SEO from "../components/SEO"

type Props = {
  data: PostsQuery
  pageContext: {
    currentPage: number
    limit: number
    pageCount: number
    skip: number
  }
}

const PostsTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const title = "記事一覧"
  const breadcrumbs = [{ name: "ホーム", url: "/" }, { name: title }]

  return (
    <>
      <SEO title={title} />
      <Layout breadcrumbs={breadcrumbs}>
        <Typography component="h2" gutterBottom variant="h5">
          {title}
        </Typography>

        <Posts posts={data} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query Posts($skip: Int!, $limit: Int!) {
    allMicrocmsPosts(
      limit: $limit
      skip: $skip
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          category {
            name
          }
          content {
            product {
              eyecatch {
                url
              }
              feature
              name
              url
            }
          }
          eyecatch {
            url
          }
          id
          publishedAt(formatString: "YYYY/MM/DD")
          postsId
          slug
          title
        }
      }
    }
  }
`

export default PostsTemplate
